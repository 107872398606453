import '../scss/home.scss';
// HOMEPAGE JS

jQuery(function ($) {

    $('.slick_home').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
    });

    $('.slider-navigation .prev').on('click', function () {
        $('.slick_home').slick('slickPrev');
    });
    $('.slider-navigation .next').on('click', function () {
        $('.slick_home').slick('slickNext');
    });

});
